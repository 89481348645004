<template>
    <div>
        <AverageNumberOfEmployees
            v-if="cardData.averageNumberOfEmployees != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.averageNumberOfEmployees.headTitle"
            :toggle-value="cardData.averageNumberOfEmployees.add"
            @toggle-change="handleToggleChange"
            :average-number-of-employees-data="cardData.averageNumberOfEmployees"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.averageNumberOfEmployees)"
            @move-down="moveDown(index, cardData.averageNumberOfEmployees)"
            :index-number="index"
        />
        <EquipmentToolsAndInstallations
            v-if="cardData.equipmentToolsAndInstallations != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.equipmentToolsAndInstallations.headTitle"
            :toggle-value="cardData.equipmentToolsAndInstallations.add"
            @toggle-change="handleToggleChange"
            :data="cardData.equipmentToolsAndInstallations"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.equipmentToolsAndInstallations)"
            @move-down="moveDown(index, cardData.equipmentToolsAndInstallations)"
            :index-number="index"
        />
        <ImprovementExpensesOtherProperty
            v-if="cardData.improvementExpensesOtherProperties != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.improvementExpensesOtherProperties.headTitle"
            :toggle-value="cardData.improvementExpensesOtherProperties.add"
            @toggle-change="handleToggleChange"
            :data="cardData.improvementExpensesOtherProperties"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.improvementExpensesOtherProperties)"
            @move-down="moveDown(index, cardData.improvementExpensesOtherProperties)"
            :index-number="index"
        />
        <OtherLongTermReceivables
            v-if="cardData.otherLongTermReceivables != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.otherLongTermReceivables.headTitle"
            :toggle-value="cardData.otherLongTermReceivables.add"
            @toggle-change="handleToggleChange"
            :data="cardData.otherLongTermReceivables"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.otherLongTermReceivables)"
            @move-down="moveDown(index, cardData.otherLongTermReceivables)"
            :index-number="index"
        />
        <LongTermLiabilities
            v-if="cardData.longTermLiabilities != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.longTermLiabilities.headTitle"
            :toggle-value="cardData.longTermLiabilities.add"
            @toggle-change="handleToggleChange"
            :data="cardData.longTermLiabilities"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.longTermLiabilities)"
            @move-down="moveDown(index, cardData.longTermLiabilities)"
            :index-number="index"
        />
        <CheckAccountCredit
            v-if="cardData.checkAccountCredits != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.checkAccountCredits.headTitle"
            :toggle-value="cardData.checkAccountCredits.add"
            @toggle-change="handleToggleChange"
            :data="cardData.checkAccountCredits"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.checkAccountCredits)"
            @move-down="moveDown(index, cardData.checkAccountCredits)"
            :index-number="index"
        />
        <ContingentLiabilities
            v-if="cardData.contingentLiabilities != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.contingentLiabilities.headTitle"
            :toggle-value="cardData.contingentLiabilities.add"
            @toggle-change="handleToggleChange"
            :contingent-liabilities-data="cardData.contingentLiabilities"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.contingentLiabilities)"
            @move-down="moveDown(index, cardData.contingentLiabilities)"
            :index-number="index"
        />
        <PlacedSecurities
            v-if="cardData.placedSecurities != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.placedSecurities.headTitle"
            :toggle-value="cardData.placedSecurities.add"
            @toggle-change="handleToggleChange"
            :placed-securities-data="cardData.placedSecurities"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.placedSecurities)"
            @move-down="moveDown(index, cardData.placedSecurities)"
            :index-number="index"
        />
        <SignificantEventsAfterYearEnd
            v-if="cardData.significantEventsAfterYearEnds != null"
            @saveData="saveData()"
            @close="closeCard"
            :title="cardData.significantEventsAfterYearEnds.headTitle"
            :toggle-value="cardData.significantEventsAfterYearEnds.add"
            @toggle-change="handleToggleChange"
            :data="cardData.significantEventsAfterYearEnds"
            :notes-data="notesData"
            @move-up="moveUp(index, cardData.significantEventsAfterYearEnds)"
            @move-down="moveDown(index, cardData.significantEventsAfterYearEnds)"
            :index-number="index"
        />
        <div v-if="cardData.newCards != null">
            <NewCard
                @saveData="saveData()"
                @close="closeCard"
                @delete="removeCard(index)"
                :title="cardData.newCards.headTitle"
                :toggle-value="true"
                @toggle-change="handleToggleChange"
                :input-text="cardData.newCards"
                @update:text="cardData.newCards = $event"
                @move-up="moveUp(index, cardData.newCards)"
                @move-down="moveDown(index, cardData.newCards)"
                :input-disabled="inputDisabled"
                :use-notes-number="true"
                :notes-data="notesData"
                :data="cardData.newCards"
            />
        </div>
        <div v-if="isLastIndex" class="flex flex-row pt-8">
            <el-form>
                <el-form-item class="pr-2">
                    <el-button size="medium" type="primary" @click="addCard">Lägg till not</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        ContingentLiabilities: () => import("./ContingentLiabilities.vue"),
        PlacedSecurities: () => import("./PlacedSecurities.vue"),
        SignificantEventsAfterYearEnd: () => import("./SignificantEventsAfterYearEnd.vue"),
        AverageNumberOfEmployees: () => import("./AverageNumberOfEmployees.vue"),
        EquipmentToolsAndInstallations: () => import("./EquipmentToolsAndInstallations.vue"),
        ImprovementExpensesOtherProperty: () => import("./ImprovementExpensesOtherProperty.vue"),
        OtherLongTermReceivables: () => import("./OtherLongTermReceivables.vue"),
        LongTermLiabilities: () => import("./LongTermLiabilities.vue"),
        CheckAccountCredit: () => import("./CheckAccountCredit.vue"),
        NewCard: () => import("../../accountingPrinciples/components/NewCard.vue"),
    },
    props: {
        annualReportId: {
            type: String,
            required: true,
        },
        cardData: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        lastIndex: {
            type: Number,
        },
        notesData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            inputDisabled: true,
        };
    },
    methods: {
        async saveData() {
            this.$emit("saveData");
        },
        async closeCard(value) {
            this.$emit("close", value);
        },
        async handleToggleChange(eventData) {
            const eventSources = [
                { source: 1, value: "contingentLiabilities" },
                { source: 2, value: "placedSecurities" },
                { source: 3, value: "significantEventsAfterYearEnds" },
                { source: 4, value: "averageNumberOfEmployees" },
                { source: 5, value: "equipmentToolsAndInstallations" },
                { source: 6, value: "improvementExpensesOtherProperties" },
                { source: 7, value: "otherLongTermReceivables" },
                { source: 8, value: "longTermLiabilities" },
                { source: 9, value: "checkAccountCredits" },
            ];

            const eventSource = eventSources.find(item => item.source === eventData.source);
            if (eventSource) {
                this.cardData[eventSource.value].add = eventData.value;
                if (!eventData.value) this.$emit("move-unselected-to-bottom", eventData);
            }
            this.$emit("saveData");
        },
        addCard() {
            for (let index = 0; index < this.notesData.cards.length; index++) {
                const row = this.notesData.cards[index];
                const card = Object.values(row).find(card => card !== null);
                if (card && card.add === false) {
                    const chosenIndex = index;
                    this.notesData.cards.splice(chosenIndex, 0, {
                        newCards: {
                            headTitle: "",
                            text: "",
                            addTable: false,
                            tableRows: [
                                { columnOne: "", columnTwo: "" },
                                { columnOne: "", columnTwo: "" },
                            ],
                            notesNumber: index + 2,
                        },
                    });
                    break;
                }
            }
            this.$emit("saveData");
        },
        removeCard(index) {
            this.notesData.cards.splice(index, 1);
        },
        moveUp(index, card) {
            this.$emit("move-up", { index: index, card: card });
        },
        moveDown(index, card) {
            this.$emit("move-down", { index: index, card: card });
        },
    },
    computed: {
        isLastIndex() {
            return this.index === this.lastIndex - 1;
        },
    },
};
</script>
